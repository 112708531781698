import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {DatePipe, registerLocaleData, ViewportScroller} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from '@components/menu-item/menu-item.component';
import {ControlSidebarComponent} from '@modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from '@components/sidebar-search/sidebar-search.component';
import {UiKitComponent} from "@/UI-kit/ui-kit.component";
import {ConfigLoader} from "@/config.loader";
import {ConfigService} from "@/config.service";
import {UserManagementComponent} from "@pages/user-management/user-management.component";
import {VerifyProfileComponent} from "@pages/verify-profile/verify-profile.component";
import {SwiperModule} from "swiper/angular";
import {NgxSpinnerModule} from "ngx-spinner";
import {ProductComponent} from "@pages/product/product.component";
import {PostComponent} from "@pages/post/post.component";
import {ProductDetailComponent} from "@pages/product-detail/product-detail.component";
import {PostDetailsComponent} from "@pages/post-detail-view/post-detail.component";
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {OrdersComponent} from "@pages/orders/orders.component";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {ProductOrdersComponent} from "@pages/orders/product-orders/product-orders.component";
import {PostOrdersComponent} from "@pages/orders/post-orders/post-orders.component";
import { PaymentComponent } from '@pages/payment/payment.component';
import {EnquiryComponent} from "@pages/enquiry/enquiry.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {UserRegistrationComponent} from "@pages/registration/user-registration/user-registration.component";
import {BusinessInfoComponent} from "@pages/registration/business-info/business-info.component";
import { BusinessDetailsComponent } from '@pages/business/business-details/business-details.component';



defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        UiKitComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        UserManagementComponent,
        VerifyProfileComponent,
        ProductComponent,
        PostComponent,
        PostDetailsComponent,
        ProductDetailComponent,
        OrdersComponent,
        ProductOrdersComponent,
        PostOrdersComponent,
        PaymentComponent,
        EnquiryComponent,
        UserRegistrationComponent,
        BusinessInfoComponent,
        BusinessDetailsComponent,
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        FormsModule,
        SwiperModule,
        NgxSpinnerModule,
        MatCardModule,
        MatButtonModule,
        Ng2SearchPipeModule,
        MatTooltipModule
    ],
    providers: [ DatePipe,
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigLoader,
            deps: [ConfigService],
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
