import {Component, OnInit} from "@angular/core";
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AppService} from "@services/app.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {Subject, takeUntil} from 'rxjs';
import {UserDetails} from "@/model/user-details/UserDetails";
import {CommodityDetails} from "@/model/commodity/CommodityDetails";

@Component({
    selector: 'app-user-register',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss']
})

export class UserRegistrationComponent implements OnInit {

    destroy$: Subject<boolean> = new Subject<boolean>();
    //base64 file list
    base64FileList: string[] = [];

    cities: string[];

    farmLocation: string = "";
    fpoLinkName: string = "";
    fpoName: string = "";
    isFarmers: boolean = false;
    isFPO: boolean = false;
    isServiceProvider: boolean = false;

    userDetails: UserDetails | undefined;

    userRegistrationId: number = 0;

    commodityDetails: CommodityDetails[] = [];
    fpoDetailList: UserDetails[] = [];

    districtsList = [
        "Ariyalur",
        "Chengalpattu",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kallakurichi",
        "Kanchipuram",
        "Kanniyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Mayiladuthurai",
        "Nagapattinam",
        "Namakkal",
        "Nilgiris",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Ranipet",
        "Salem",
        "Sivagangai",
        "Tenkasi",
        "Thanjavur",
        "Theni",
        "Thoothukudi",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupathur",
        "Tiruppur",
        "Tiruvallur",
        "Tiruvannamalai",
        "Tiruvarur",
        "Vellore",
        "Viluppuram",
        "Virudhunagar"
    ]

    constructor(private route: Router, private appService: AppService,
                private spinner: NgxSpinnerService, private toastr: ToastrService) {
        this.cities = this.appService.getCitiesByState('IN', 'Tamil Nadu');
        console.log(this.cities);
    }

    userRegisterForm = new UntypedFormGroup({
        userRegistrationId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        countryCode: new UntypedFormControl("91", [Validators.required, Validators.nullValidator]),
        phoneNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
        email: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.email]),
        enrollmentType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        accountStatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        selfDescription: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        city: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pinCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
        verificationImageList: new UntypedFormArray([]),
        addressDetails: new UntypedFormArray([]),
    });

    businessRegForm = new UntypedFormGroup({
        farmDetailsId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        primaryCropsGrown: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoLinkName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoRegistrationNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoCity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        farmAddress: new UntypedFormArray([]),
    })

    serviceRegFrom = new UntypedFormGroup({
        businessName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        typeOfService: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })

    public userRegisterError = {
        phoneNumber: '',
        city: '',
    }

    userRegisterValidation() {
        this.userRegisterError.city = ''
        this.userRegisterError.phoneNumber = ''

        let hasError = false;

        if (this.userRegisterForm.get('phoneNumber')?.invalid) {
            if (this.userRegisterForm.get('phoneNumber').errors.required) {
                this.userRegisterError.phoneNumber = "Phone Number is required";
            }
            if (this.userRegisterForm.get('phoneNumber').errors.pattern) {
                this.userRegisterError.phoneNumber = "Please, Enter 10 digit Valid Phone Number.";
            }
            hasError = true;
        }
        if (this.userRegisterForm.get('city')?.invalid) {
            this.userRegisterError.city = "City is required";
            hasError = true;
        }

        if (!hasError) {
            this.saveUserDetails();
        }
    }


    ngOnInit() {
        this.getAllCommodity();
        this.getAllFPODetails();
    }


    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }


    getAllCommodity() {
        this.spinner.show();
        this.appService.getCommodityList()
            .pipe(takeUntil(this.destroy$))
            .subscribe((commodity) => {
                    this.spinner.hide();
                    this.commodityDetails = commodity;
                    console.log('commodity list::', this.commodityDetails);
                }, (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();
                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                }
            );
    }

    getAllFPODetails() {
        this.spinner.show();
        this.appService.getAllFPODetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((fpoDetails) => {
                    this.spinner.hide();
                    this.fpoDetailList = fpoDetails;
                    console.log('FPO list::', this.fpoDetailList);
                }, (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();
                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                }
            );
    }


    onSubmit() {
        this.saveUserDetails();
    }

    userType(event: any) {
        const userType = event.target.value;
        if (userType == 'Farmers') {
            this.isFarmers = true;
            this.isFPO = false;
            this.isServiceProvider = false;
        } else if (userType == 'FPO') {
            this.isFarmers = false;
            this.isFPO = true;
            this.isServiceProvider = false;
        } else if (userType == 'Service Provider') {
            this.isServiceProvider = true;
            this.isFarmers = false;
            this.isFPO = false;
        }
        this.userRegisterForm.controls['enrollmentType'].setValue(userType);
        console.log(userType)
    }

    selectFPO(event: any) {
        this.fpoName = event.target.value;
        if (this.farmLocation) {
            this.fpoLinkName = `${this.fpoName} - ${this.farmLocation}`;
        }
    }

    selectCity(event: any, locationType: string) {
        if (locationType == 'city') {
            const district = event.target.value;
            this.userRegisterForm.controls['city'].setValue(district);
            console.log(district);
        } else if (locationType == 'Farm Location') {
            this.farmLocation = event.target.value;
            console.log(this.farmLocation);
            this.fpoLinkName = `${this.fpoName} - ${this.farmLocation}`;
            console.log(this.fpoLinkName);
        } else if (locationType == 'FPO Location') {
            this.farmLocation = event.target.value;
        }
    }


    handleFileInput(event: Event): void {
        const files = (event.target as HTMLInputElement).files;
        if (files) {
            Array.from(files).forEach(file => this.convertToBase64(file));
        }
    }

    private convertToBase64(file: File): void {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result as string;
            const base64Value = base64String.split(',')[1];
            // Push the file data to the verificationImageList FormArray
            const fileData = {
                imageData: base64Value,
                imageDescription: 'User register files', // Set as needed
                imageExtension: file.name.split('.').pop() || '',
                imageType: "VERIFICATION",
                imageFileName: file.name
            };
            console.log(fileData)

            this.addImageToForm(fileData);
        };
        reader.onerror = error => {
            console.error('Error reading file:', error);
        };
        reader.readAsDataURL(file);
    }

    private addImageToForm(fileData: { imageData: string; imageDescription: string; imageFileName: string; imageExtension: string; imageType: string }): void {
        const imageForm = new UntypedFormGroup({
            imageData: new UntypedFormControl(fileData.imageData),
            imageDescription: new UntypedFormControl(fileData.imageDescription),
            imageExtension: new UntypedFormControl(fileData.imageExtension),
            imageType: new UntypedFormControl(fileData.imageType),
            imageFileName: new UntypedFormControl(fileData.imageFileName)
        });
        (this.userRegisterForm.get('verificationImageList') as UntypedFormArray).push(imageForm);
    }

    saveUserDetails() {
        const addressList = new UntypedFormGroup({
            city: new UntypedFormControl(this.userRegisterForm.controls['city'].value),
            pinCode: new UntypedFormControl(this.userRegisterForm.controls['pinCode'].value),
            addressType: new UntypedFormControl(''),
        });
        console.log(addressList);
        (this.userRegisterForm.get('addressDetails') as UntypedFormArray).push(addressList);
        console.log('save form group::', this.userRegisterForm.value);
        this.spinner.show();
        this.appService.saveUserRegisterDetails(this.userRegisterForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (userData) => {
                    this.spinner.hide();
                    console.log('User Details::', userData);
                    this.userDetails = userData;
                    this.userRegistrationId = this.userDetails.userRegistrationId;
                    if (this.isServiceProvider) {
                        this.saveServiceProviderBusinessDetails();
                    } else {
                        this.saveBusinessDetails();
                    }
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                }
            );
    }


    //save business details
    saveBusinessDetails() {
        const farmAddress = new UntypedFormGroup({
            city: new UntypedFormControl(this.farmLocation)
        });
        console.log(farmAddress);
        this.businessRegForm.controls['fpoLinkName'].setValue(this.fpoLinkName);
        (this.businessRegForm.get('farmAddress') as UntypedFormArray).push(farmAddress);
        this.spinner.show();
        this.appService.saveFarmerDetails(this.userRegistrationId, this.businessRegForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (registerData) => {
                    this.spinner.hide();
                    console.log('User Details::', registerData);
                    this.toastr.success(`Your details registered successfully`);
                    this.businessRegForm.reset();
                    this.userRegisterForm.reset();
                },

                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                }
            );
    }

    saveServiceProviderBusinessDetails() {
        this.spinner.show();
        console.log("Save Details", this.serviceRegFrom.value)
        this.appService.saveServiceProviderBusinessDetails(this.userRegistrationId, this.serviceRegFrom.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                this.spinner.hide();
                console.log('Response from save:', response);
                this.toastr.success(`Your details registered successfully`);
                this.userRegisterForm.reset();
                this.serviceRegFrom.reset();
            }, (error: any) => {
                console.error("Error:", error);
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

}