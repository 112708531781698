<section class="content">
    <div class="container-fluid">
        <div class="row">
            <form [formGroup]="newBusinessDetails" (ngSubmit)="onSubmit()">
                <div class="row mt-3">

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                                (change)="onShowEnrollDetails($event)">
                                <option selected>Select Type of User</option>
                                <option *ngFor="let name of this.getAllEnrollTypes">{{name}}</option>
                            </select>
                            <label for="floatingSelect">Select Type of User</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                                (change)="onShowUserId($event)">
                                <option selected>Select Name</option>
                                <option *ngFor="let name of this.getUserNameDetails" [value]="name.userRegistrationId">
                                    {{name.name}}</option>
                            </select>
                            <label for="floatingSelect">Select Name</label>
                        </div>
                    </div>

                </div>

                <div class="row mt-3" *ngIf="showServiceDetails">

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Business Name"
                                formControlName="businessName">
                            <label class="text-gray font-weight-lighter">Business Name</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Service License Number"
                                formControlName="serviceLicenseNumber">
                            <label class="text-gray font-weight-lighter">Service License Number </label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Contact Number" maxlength="10"
                                (keypress)="OnlyNumbers($event)" formControlName="contactNumber">
                            <label class="text-gray font-weight-lighter">Contact Number</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="GST Number" formControlName="gstNov">
                            <label class="text-gray font-weight-lighter">GST Number</label>
                        </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Street Address"
                                formControlName="streetName">
                            <label class="text-gray font-weight-lighter">Street Address</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="City" formControlName="city">
                            <label class="text-gray font-weight-lighter">City</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="State" formControlName="state">
                            <label class="text-gray font-weight-lighter">State</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Pin Code" maxlength="6"
                                (keypress)="OnlyNumbers($event)" formControlName="pinCode">
                            <label class="text-gray font-weight-lighter">Pin Code</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Service Reach/Area Covered"
                                formControlName="serviceReach">
                            <label class="text-gray font-weight-lighter">Service Reach/Area Covered</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Availability"
                                formControlName="availability">
                            <label class="text-gray font-weight-lighter">Availability</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Pricing Model"
                                formControlName="pricingModel">
                            <label class="text-gray font-weight-lighter">Pricing Model</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Latitude" formControlName="latitude">
                            <label class="text-gray font-weight-lighter">Latitude</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Longitude" formControlName="longitude">
                            <label class="text-gray font-weight-lighter">Longitude</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Name" formControlName="bankName">
                            <label class="text-gray font-weight-lighter">Bank Name</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Holder Name"
                                formControlName="accountHolderName">
                            <label class="text-gray font-weight-lighter">Bank Holder Name</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Account Number"
                                (keypress)="OnlyNumbers($event)" formControlName="accountNumber">
                            <label class="text-gray font-weight-lighter">Bank Account Number</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank IFSC Code"
                                formControlName="ifscCode">
                            <label class="text-gray font-weight-lighter">Bank IFSC Code</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Branch Name"
                                formControlName="branchName">
                            <label class="text-gray font-weight-lighter">Bank Branch Name</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="file" class="form-control" placeholder="Certifications & Memberships"
                                (change)="onFileSelected($event, 'certifications')">
                            <label class="text-gray font-weight-lighter">Certifications & Memberships</label>
                        </div>
                    </div>
                    <!-- Display Selected Images in Card Format for Certifications -->
                    <div class="col-12 mb-2" *ngIf="certificationsFiles.length > 0">
                        <div class="row">
                            <div class="col-md-3" *ngFor="let file of certificationsFiles; let i = index">
                                <div class="card">
                                    <img [src]="file.documentPath" *ngIf="file.documentExtension !== 'pdf'"
                                        class="card-img-top" alt="Selected Image">
                                    <div class="card-body text-center">
                                        <button type="button" class="btn btn-danger btn-sm"
                                            (click)="removeFile(i, 'certifications')">Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <textarea type="file" class="form-control" placeholder="Description"
                                formControlName="description"></textarea>
                            <label class="text-gray font-weight-lighter">Description</label>
                        </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="file" class="form-control" placeholder="Business Documents upload"
                                (change)="onFileSelected($event, 'businessDocuments')">
                            <label class="text-gray font-weight-lighter">Business Documents upload</label>
                        </div>
                    </div>

                    <!-- Display Selected Images in Card Format for Business Documents -->
                    <div class="col-12 mb-2" *ngIf="businessDocumentsFiles.length > 0">
                        <div class="row">
                            <div class="col-md-3" *ngFor="let file of businessDocumentsFiles; let i = index">
                                <div class="card">
                                    <img [src]="file.documentPath" *ngIf="file.documentExtension !== 'pdf'"
                                        class="card-img-top" alt="Selected Image">
                                    <div class="card-body text-center">
                                        <button type="button" class="btn btn-danger btn-sm"
                                            (click)="removeFile(i, 'businessDocuments')">Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center mt-4">
                        <button type="submit" class="btn btn-primary btn-block col-2">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="row">
            <form [formGroup]="newFarmerDetails" (ngSubmit)="onSubmit()">
                <div class="row mt-3">
                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Farm Size" formControlName="farmSize">
                            <label class="text-gray font-weight-lighter">Farm Size</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Primary Crops Grown"
                                formControlName="primaryCropsGrown">
                            <label class="text-gray font-weight-lighter">Primary Crops Grown</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Contact Number" maxlength="10"
                                (keypress)="OnlyNumbers($event)" formControlName="contactNumber">
                            <label class="text-gray font-weight-lighter">Contact Number</label>
                        </div>
                    </div>


                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Village / Town"
                                formControlName="streetName">
                            <label class="text-gray font-weight-lighter">Village / Town</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="City" formControlName="city">
                            <label class="text-gray font-weight-lighter">City</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="State" formControlName="state">
                            <label class="text-gray font-weight-lighter">State</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Pin Code" maxlength="6"
                                (keypress)="OnlyNumbers($event)" formControlName="pinCode">
                            <label class="text-gray font-weight-lighter">Pin Code</label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Latitude" formControlName="latitude">
                            <label class="text-gray font-weight-lighter">Latitude</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Longitude" formControlName="longitude">
                            <label class="text-gray font-weight-lighter">Longitude</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Name" formControlName="bankName">
                            <label class="text-gray font-weight-lighter">Bank Name</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Holder Name"
                                formControlName="accountHolderName">
                            <label class="text-gray font-weight-lighter">Bank Holder Name</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Account Number"
                                (keypress)="OnlyNumbers($event)" formControlName="accountNumber">
                            <label class="text-gray font-weight-lighter">Bank Account Number</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank IFSC Code"
                                formControlName="ifscCode">
                            <label class="text-gray font-weight-lighter">Bank IFSC Code</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Bank Branch Name"
                                formControlName="branchName">
                            <label class="text-gray font-weight-lighter">Bank Branch Name</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                                (change)="onShowFPODetails($event)">
                                <option selected>Select FPO Name</option>
                                <option *ngFor="let name of this.fpoDetails">
                                    {{name.name}}</option>
                            </select>
                            <label for="floatingSelect">Select FPO Name</label>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center mt-4">
                        <button type="submit" class="btn btn-primary btn-block col-2">Submit</button>
                    </div>

                </div>
            </form>
        </div>

    </div>
</section>