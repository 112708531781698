import { Component, OnInit, Renderer2 } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AppService } from "@services/app.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfigService } from "@/config.service";
import { Subject, takeUntil } from 'rxjs';
import { ProductOrders } from "@/model/orders/ProductOrders";
import { PostOrders } from "@/model/orders/PostOrders";
import { val } from "dom7";
import { UserDetails } from "@/model/user-details/UserDetails";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BusinessDocumentDetails } from "@/model/business/BusinessDocumentDetails";

@Component({
    selector: 'app-business-details',
    templateUrl: './business-details.component.html',
    styleUrls: ['./business-details.component.scss']
})

export class BusinessDetailsComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    getAllRegistrationDetails: UserDetails[] = [];
    getUserNameDetails: UserDetails[] = [];
    getAllEnrollTypes: String[] = [];
    base64Images: string[] = [];
    readonly MAX_FILE_SIZE = 2 * 1024 * 1024;
    readonly MAX_IMAGE_COUNT = 6;
    userRegId: number = 0;
    ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'application/pdf'];
    businessDocumentImage: BusinessDocumentDetails[] = [];
    fpoDetails: any[] = [];
    certificationsFiles: any[] = [];
    businessDocumentsFiles: any[] = [];
    showForms: boolean = false;
    showServiceDetails: boolean = false;
    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService
    ) {
    }

    newBusinessDetails = new UntypedFormGroup({
        businessId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessEmail: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessLicenseNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        tradeLicenseNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        serviceLicenseNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        enrollmentType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        contactNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        serviceReach: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        availability: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pricingModel: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        description: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        latitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        longitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        sourceRegions: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        gstNov: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessStatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        distributionRegions: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        areaOfCoverage: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        typeOfService: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        countryCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessAddress: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessDocumentDetails: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        streetName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        city: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        state: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pinCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        bankName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        accountNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        ifscCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        accountHolderName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        branchName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    })

    newFarmerDetails =  new UntypedFormGroup ({
        farmDetailsId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmSize: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        typeOfUser: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        primaryCropsGrown: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        enrollmentType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        contactNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        description: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        latitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        longitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmsStatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        fpoLinkName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        fpoRegId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        noOfMembers: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        fpoRegistrationNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        countryCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        userRegId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmAddress: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        bankDetails: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmerMapped: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        streetName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        city: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        state: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pinCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        bankName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        accountNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        ifscCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        accountHolderName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        branchName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    })

    ngOnInit() {
        this.getRegistrationDetails();
    }

    onSubmit() {
        const addressDetails = [{
            streetName: this.newBusinessDetails.get('streetName')?.value,
            city: this.newBusinessDetails.get('city')?.value,
            state: this.newBusinessDetails.get('state')?.value,
            pinCode: this.newBusinessDetails.get('pinCode')?.value
        }]
        this.newBusinessDetails.controls['businessAddress'].setValue(addressDetails);
        this.saveServiceProviderBusinessDetails();
    }

    getRegistrationDetails() {
        this.appService.getAllUserRegisteredDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log('Registration details::', data);
                this.getAllRegistrationDetails = data;
                const enrollmentType = new Set(this.getAllRegistrationDetails.map(item => item.enrollmentType));
                this.getAllEnrollTypes = Array.from(enrollmentType).filter(item => item != "");
                this.fpoDetails = this.getAllRegistrationDetails.filter(item => item.enrollmentType === 'FPO')
                console.log('Registration details::', this.fpoDetails)
            }, (error: any) => {
                this.toastr.error(error.error.msg || "Something Went Wrong, Please Try Again....")
            })
    }

    onShowEnrollDetails(event: any) {
        const Values = event.target.value;
        this.newBusinessDetails.controls['enrollmentType'].setValue(Values);
        this.getUserNameDetails = this.getAllRegistrationDetails.filter(item => item.enrollmentType === Values);
        this.showForms = true;
        if(Values === 'Service Provider'){
            this.showServiceDetails = true;
        }else {
            this.showServiceDetails = false;
        }
    }

    onShowUserId(event: any) {
        const Values = event.target.value;
        this.userRegId = Number(Values);
        console.log(this.userRegId);
    }

    onFileSelected(event: Event, type: string): void {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const file = input.files[0];

            // Validate file type
            if (!this.ALLOWED_FORMATS.includes(file.type)) {
                this.toastr.error("Only JPG, PNG, and PDF files are allowed.");
                return;
            }

            const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
            if (file.size > maxSizeInBytes) {
                this.toastr.error("File size must be below 2MB.");
                return;
            }

            const reader = new FileReader();
            reader.onload = (e: any) => {
                const base64String = e.target.result as string;
                const base64Value = base64String.split(',')[1];

                const fileData = {
                    documentPath: base64String,
                    documentData: base64Value,
                    referenceId: 0,
                    documentType: "DOCUMENT",
                    documentFileName: file.name,
                    businessDocumentId: 0,
                    documentDescription: type,
                    createdAt: 0,
                    userId: 0,
                    documentExtension: file.type.split('/')[1]
                };

                // Push file data to the respective array based on the input type
                if (type === 'certifications') {
                    this.certificationsFiles.push(fileData);
                    this.newBusinessDetails.controls['certificationsDetails'].setValue(this.certificationsFiles);
                } else if (type === 'businessDocuments') {
                    this.businessDocumentsFiles.push(fileData);
                    this.newBusinessDetails.controls['businessDocumentDetails'].setValue(this.businessDocumentsFiles);
                }
                console.log("File data added:", fileData);
            };

            reader.readAsDataURL(file);
            input.value = ''; // Reset the file input
        }
    }

    // Method to view the file
    viewFile(file: any): void {
        const newWindow = window.open();
        newWindow?.document.write(`<iframe src="${file.documentPath}" width="100%" height="100%"></iframe>`);
    }

    // Method to remove file from the list based on the type
    removeFile(index: number, type: string): void {
        if (type === 'certifications') {
            this.certificationsFiles.splice(index, 1);
            this.newBusinessDetails.controls['certificationsDetails'].setValue(this.certificationsFiles);
        } else if (type === 'businessDocuments') {
            this.businessDocumentsFiles.splice(index, 1);
            this.newBusinessDetails.controls['businessDocumentDetails'].setValue(this.businessDocumentsFiles);
        }
    }

    // only number accept for input 
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    saveServiceProviderBusinessDetails() {
        console.log("Save Details", this.newBusinessDetails.value)
        this.appService.saveServiceProviderBusinessDetails(this.userRegId, this.newBusinessDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                console.log('Response from save:', response);
                this.toastr.success("Business Details saved successfully.");
                this.router.navigate(['/main/business-details']);
            }, (error: any) => {
                this.toastr.error(error.error.msg || "Something Went Wrong, Please Try Again....")
            })
    }

    onShowFPODetails(event: any){

    }
}